/* PrimeNG Scaling */
html {
    font-size: 16px;
}

/* Font Family Definition */
body {
    font-family: 'DM Sans', sans-serif;
}

/* Order */
@layer tailwind, reset, components, utilities;

/* Tailwind */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'primeicons/primeicons.css';

/* Reset CSS */
@layer reset {
    button,
    input {
        /* CSS to Reset */
    }
}

/* Custom Fonts */
@font-face {
    font-family: 'MADE Soulmaze';
    src: url('/assets/fonts/MADESoulmaze.otf') format('opentype');
}

@import "./assets/fonts/dm-sans/stylesheet.css";

/* Custom Styles */
mark {
    background-color: transparent;
}

::selection {
    background-color: rgb(255, 159, 159);
    color: black;
}

::-moz-selection {
    background-color: rgb(255, 159, 159);
    color: black;
}

.font-brand {
    font-family: MADE Soulmaze, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
}


.p-overlay-mask {
    background-color: rgba(0, 0, 0, 0.05);
}

.p-datatable-loading-icon {

}
// TODO: replace this with new theming in primeng v18 when it is available as of today 19/09/2024 only fontsize is available

.p-datepicker-weeklabel-container{
    padding: 6px;
    background: rgb(220, 38, 38);;
    border-radius: 10px;
    color: white;
    opacity: unset;
}
.p-datepicker-weekheader{
    color: rgb(220, 38, 38);
    opacity: 100;
    font-size: 16px;
    line-height: 0;
    padding: 6px;
    margin: 0;
}
.p-datepicker-today > span{
    background: black;
    color: white;
}

.ngx-ic-source-image {
    margin: auto;
}
.p-tooltip {
    background: transparent !important;
    border: none !important;
}

.p-tooltip .p-tooltip-arrow {
    display: none !important;
}

.p-tooltip-text {
    background: #ffffff !important;
    color: #111827 !important;
    border: 1px solid #e5e7eb !important;
    border-radius: 4px !important;
}
